import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, InputLabel, Select, MenuItem, Tooltip, Zoom } from '@material-ui/core';
import { Button, Container, Typography, List, ListItem, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Modal } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';
import store from './../redux/store';
//import { usuario } from '../redux/usuarios/actions';
import { fetchEmpresas } from '../redux/empresas/actions';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import { InputAdornment } from '@material-ui/core';
import { Email, Phone, Business, Link, FileCopy } from '@material-ui/icons';
import { getEmpresas } from '../api';
import { green } from '@material-ui/core/colors';
import moment from 'moment'
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CardMedia from '@mui/material/CardMedia';
import {
  useGridApiRef,
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridValueGetterParams,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { Autocomplete, Avatar, CardActions, CardHeader, Collapse, Divider, LinearProgress, ListItemButton, ListSubheader, Stack, styled } from '@mui/material';

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DefaultizedPieValueType } from '@mui/x-charts';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';


import { client, CHAT_GPT_RESPONSE, AUTH_USER, getUsers, filterCompany, whoami, UPDATE_USER, REMOVE_COMPANY, CREATE_COMPANY, getCompanies, UPDATE_COMPANY, getCategories, filterChatgpt } from '../graphqlClient/graphql'
import { useMutation } from '@apollo/client';
import { serverConfig } from '../api/apiConfig';
import Notifications from './../components/notifications/Notifications';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    //alignItems: 'center',
    height: '100%',
  },
  content: {
    textAlign: 'center',
  },
  messages: {
    textAlign: 'left',
    width: '100%',
    height: 'calc(100vh - 250px)',
    maxHeight: 'calc(100vh - 250px)'
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  listContainer: {
    //maxWidth:'80%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    justifyContent: 'center',
    alignItems: 'flex-start',

    maxHeight: 'calc(100vh - 100px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'green',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'darkgreen',
    },
  },
  listContainerChat: {
    //maxWidth:'80%',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    justifyContent: 'left',
    alignItems: 'flex-start',

    maxHeight: 'calc(100vh - 250px)',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'green',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'darkgreen',
    },
  },
  listItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  ai: {
    color:'#2e2734',
    padding:'5px',
    marginLeft: theme.spacing(1),
  },
  user: {
    padding:'5px',
    marginLeft: theme.spacing(1),
  },
  addButton: {
    margin: theme.spacing(2),
    backgroundColor: green[500],
    color: 'white',
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

type PropsFromRedux = ConnectedProps<typeof connector>;

const AiVisaGuidePage: React.FC<PropsFromRedux> = ({ user, isLoadingUsuarios, isLoadingUsuariosByFilter, isLoadingCreateUsuario, empresas }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const messages: any = []
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [selectedUsuario, setSelectedUsuario] = React.useState('');

  const [loading, setLoading] = React.useState(false);


  const [result, setResult] = React.useState<any>([]);
  const [categories, setCategories] = React.useState<any>([]);
  const [companies, setCompanies] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>({});
  const [selectedCompany, setSelectedCompany] = React.useState<any>({});
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const [save, setSave] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<any>({ key: 0, value: 'Filtro por operador' });

  const [messageData, setMessageData] = React.useState<any>('');
  const [chatId, setChatId] = React.useState<any>('');
  const [newChat, setNewChat] = React.useState<boolean>(false);
  const [startDate, setStartDate] = React.useState<any>(null);
  const [endDate, setEndDate] = React.useState<any>(null);
  const [expanded, setExpanded] = React.useState('');
  const [link, setLink] = React.useState('');
  const [chatgpt, setChatgpt] = React.useState<any>([]);
  const [ChatGptResponse, { data }] = useMutation(CHAT_GPT_RESPONSE);

  const handleExpandClick = (item: any) => {
    const newValue = expanded == item.id ? '' : item.id
    console.log(item.messages)
    setResult(item.messages)
    setChatId(item._id)
    setExpanded(newValue);
  };

  const getCategoriesAsync = async () => {

    const result: any = await getCategories()
    console.log('resutl ===>', result)

    if (result?.length) {
      setCategories(result)
    }
  }

  const getCompaniesAsync = async () => {

    const result: any = await getCompanies()
    console.log('resutl ===>', result)

    if (result?.length) {
      setCompanies(result)
    }
  }

  const getChatgptAsync = async () => {

    const result: any = await filterChatgpt({ user: user._id, type: "visachat" })
    console.log('resutl ===>', result)
    //setResult(result[0].response)
    if (result?.length) {
      setChatgpt(result)
    }else{
      setNewChat(true)
    }
  }

  const createChatgptResponse = async (message: any) => {
    setLoading(true)
    const createChatgptInput:any = {
      user: user._id,
      message,
      type: "visachat"
    }
    if(chatId){
      createChatgptInput.chatId = chatId;
    }
    try {
      const userToken = await ChatGptResponse({
        variables: {
          createChatgptInput: createChatgptInput,

        },
        onCompleted: ({ chatGptRequest }) => {

          console.log('chatGptRequest result== > ', chatGptRequest);
          /* const notData = {
            type: 'success',
            message: '',
            title: 'wescout',
            timeOut: 2500,
            callback: () => { },
            priority: true,
          }
          Notifications(notData) */
          const res = chatGptRequest.response
          
          setResult(res.messages)
          setChatId(res._id)
          setLoading(false)
        }
      });
    } catch (error: any) {
      const notData = {
        type: 'error',
        message: error.message,
        title: 'wescout',
        timeOut: 2500,
        callback: () => { },
        priority: true,
      }
      Notifications(notData)
      console.log("Erro ao atualizar usuário", error)
      setLoading(false)
    }

  }




  useEffect(() => {

    //getCategoriesAsync();
    //getCompaniesAsync();
    getChatgptAsync()

  }, []);




  useEffect(() => {
    if (isLoadingUsuarios) {
      setLoading(true)
    }
    if (loading && !isLoadingUsuarios) {

      setLoading(false)
    }
    console.log('isLoadingUsuarios ==> ', isLoadingUsuarios)

  }, [isLoadingUsuarios]);

  useEffect(() => {
    if (isLoadingCreateUsuario) {
      setLoading(true)
    }
    if (loading && !isLoadingCreateUsuario) {

      setLoading(false)
    }
    console.log('isLoadingCreateUsuario ==> ', isLoadingCreateUsuario)

  }, [isLoadingCreateUsuario]);

  const handleDeleteClick = (UsuarioId: any) => {
    console.log('UsuarioId', UsuarioId)
    setSelectedUsuario(UsuarioId);
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmation = () => {
    console.log('selectedUsuario', selectedUsuario)
    //deleteUsuario(selectedUsuario);
    setDeleteConfirmationOpen(false);
  };






  //init DataGrid dependencies

  const clearData = (d: any) => {
    const dateTransform = (moment(d).format('DD-MM-YYYY h:m'));
    return dateTransform
  }


  const formatText = (text:any)=>{
    return text.replace(/\n/g, '<br />');
  }

  const sendMessage = () => {
    console.log(messageData)
    console.log(chatId)

    createChatgptResponse(messageData)
    
  }


  return (
    <Container className={classes.container}>
      <div className='root'>
        <div className={classes.content}>
          {/* <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button> */}
          <Typography variant="h4" className={'title'}>
            AI Visa Guide
          </Typography>
          {loading ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          ) : null}
          {
            result.length||newChat ? 
            <Box sx={{ width: '100%', height: 100, }} style={{ alignSelf: 'center', paddingTop: 10, paddingBottom: 10, borderColor: 'green', borderWidth: 2 }}>
            <Stack borderRadius={15} spacing={2} direction="column" alignItems={'center'} alignContent={'center'} divider={<Divider orientation="vertical" flexItem />}>
              <div id="chatbox" className='chatbox'>
                <div className={classes.messages}>
                  {

                    result.length ? (
                      <div className={classes.listContainerChat}>
                        {
                          result.map(
                            (item: any, index: number) => (
                              item.ai ?
                              <div className={classes.ai}>
                                <b>wescout</b>:
                                <div dangerouslySetInnerHTML={{ __html: formatText(item.text) }} style={{  padding: '15px', fontFamily: 'Montserrat', textAlign: 'justify', color: 'black', margin: '0 auto' }}>

                                </div>
                               
                              </div> :
                              <div className={classes.user}>
                                <b>{user.name}</b>: 
                                <div dangerouslySetInnerHTML={{ __html: item.text }} style={{  padding: '15px', fontFamily: 'Montserrat', textAlign: 'justify', color: 'darkgray', margin: '0 auto' }}>

                                </div>
                            
                              </div>


                            ))
                        }

                      </div>) : null


                  }
                </div>
                <TextField
                  style={{ marginBottom: 5 }}
                  label="type your message here"
                  value={messageData}
                  onChange={(e) => {
                    setMessageData(e.target.value)
                    //messages.push(e.target.value)
                  }}
                />
              </div>
              <Button className={classes.addButton} variant="contained" color="primary" onClick={() => sendMessage()}>
                Send
              </Button>

            </Stack>


          </Box>
            
            : null
          }

          {
            chatgpt.length ? (
              <div className={classes.listContainer}>

                {chatgpt.map((item: any, index: any) => (
                  <Card sx={{ maxWidth: 345 }}>
                    <CardHeader
                      avatar={
                        <Avatar sx={{ bgcolor: '#0df228' }} aria-label="recipe">
                          {item.user.name.substring(0, 2)}
                        </Avatar>
                      }
                      action={
                        <IconButton aria-label="settings">
                          <MoreVertIcon />
                        </IconButton>
                      }
                      title={item.user.name}
                      subheader={clearData(item.createdAt)}
                    />
                    {/* <CardMedia
                  onClick={ ()=> setSelectedCompany(item) }
                  component="img"
                  height="194"
                  image={ item.image ? serverConfig.dev.url + 'images/' + item.image : "https://robohash.org/"+item.name} 
                  alt="Companie image"
                /> */}
                    <CardContent>
                      <Typography variant="body2" style={{ color: 'white' }}>
                        {/* Strength: {JSON.parse(item.response).grade} */}
                      </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                      <IconButton onClick={() => {
                        const notData = {
                          type: 'success',
                          message: `Result copied to clipboard`,
                          title: 'wescout',
                          timeOut: 2500,
                          callback: () => { },
                          priority: true,
                        }


                        navigator.clipboard.writeText(item.response)
                        Notifications(notData)
                      }} aria-label="copy to clipboard">
                        <FileCopy />
                      </IconButton>

                      <ExpandMore
                        expand={expanded == item._id ? true : false}
                        onClick={() => { handleExpandClick(item) }}
                        aria-expanded={expanded == item._id}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>
                    <Collapse in={expanded == item.name} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography className='subtitle'>
                          {
                            /* finalResult ? (
                                <div id="chatbox" className='chatbox'>
                                    <div dangerouslySetInnerHTML={{ __html: finalResult }} style={{ width: '100%', maxWidth: '800px', height: '300px', padding: '15px', fontFamily: 'Montserrat', textAlign: 'justify', color: 'white', margin: '0 auto', overflowY: 'scroll' }}>

                                    </div>
                                  
                                </div>
                            ) : null */
                          }
                        </Typography>

                      </CardContent>
                    </Collapse>
                  </Card>
                )

                )}


              </div>
            ) : null
          }





          <Button className='backbutton' onClick={() => navigate(-1)}>Go Back</Button>
        </div>
      </div>

      <Dialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Confirm </DialogTitle>
        <DialogContent>
          <Typography variant="body1">Are you sure you want to delete?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirmation} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>


    </Container>
  );
};

const mapStateToProps = (state: any) => ({

  user: state.user.user,
  isLoadingUsuarios: state.usuarios.isLoadingUsuarios,
  isLoadingUsuariosByFilter: state.usuarios.isLoadingUsuariosByFilter,
  isLoadingCreateUsuario: state.usuarios.isLoadingCreateUsuario,
  empresas: state.empresas.empresas
});

const mapDispatchToProps = {

};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(AiVisaGuidePage);
