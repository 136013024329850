import { ApolloClient, InMemoryCache, createHttpLink, gql, useMutation, split, from, ApolloLink } from "@apollo/client";
import { stateLink, createErrorLink } from './middlewares';
import { getMainDefinition } from '@apollo/client/utilities';

import { createUploadLink } from 'apollo-upload-client'
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { makeVar, useReactiveVar } from "@apollo/client";
import Notifications from "./../components/notifications/Notifications";
import {serverConfig} from './../api/apiConfig';

export const name = makeVar("Ti Venegas");
//import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
//loadErrorMessages()
//loadDevMessages()


const uri = serverConfig.dev.url +'graphql'
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});
const httpLink = createHttpLink({
  uri,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists

  const token = localStorage.getItem('token');
  console.log(localStorage.getItem("token"));
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      'apollo-require-preflight': true,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});




//const httpLink = new HttpLink({ uri: 'YOUR_GRAPHQL_ENDPOINT' });
const uploadLink: any = createUploadLink({ uri });
const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'mutation'
    );
  },
  uploadLink,
  authLink,
);
const apolloLinks: any = [authLink, createErrorLink(), stateLink, uploadLink];
//const client = new ApolloClient({ link, cache: new InMemoryCache() });

const cache: any = new InMemoryCache();

const client = new ApolloClient({
  //link: authLink.concat(httpLink),
  //link: from(apolloLinks),
  link: ApolloLink.from([authLink, uploadLink]),
  cache,
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
  connectToDevTools: true,

});


const AUTH_USER = gql`
      mutation Login($email: String!, $password: String!) {
      
        login(data:{email: $email, password: $password}) {
          token
          renewToken
        }
      }
    `;



const UPDATE_USER = gql`
      mutation UpdateUser($updateUserInput: UpdateUserInput!) {
        updateUser( updateUserInput :  $updateUserInput) {
          name
          email
          _id
          image
          confirmed
          employed
          company{
            _id
            name
          }
          category{
            _id
            name
          }
          
        }
      }
    `;

const UPDATE_COMPANY = gql`
      mutation UpdateCompany($updateCompanyInput: UpdateCompanyInput!) {
        updateCompany( updateCompanyInput :  $updateCompanyInput) {
          _id
          name
          description
          createdBy{_id, name, role}
          
        }
      }
    `;
const UPDATE_CATEGORY = gql`
      mutation UpdateCategory($updateCategoryInput: UpdateCategoryInput!) {
        updateCategory( updateCategoryInput :  $updateCategoryInput) {
          _id
          name
          description
          createdBy{_id, name, role}
          
        }
      }
    `;
const UPDATE_CARRERGUIDE = gql`
      mutation UpdateCarrerguide($updateCarrerguideInput: UpdateCarrerguideInput!) {
        updateCarrerguide( updateCarrerguideInput :  $updateCarrerguideInput) {
          _id
          name
          description
          createdBy{_id, name, role}
          
        }
      }
    `;
const CREATE_USER = gql`
      mutation CreateUser($createUserInput: CreateUserInput!){
        createUser( createUserInput : $createUserInput) {
          _id
          name
          email
          confirmed
          employed
        }
      }
    `;
const CHAT_GPT_RESPONSE = gql`
      mutation ChatGptRequest($createChatgptInput: CreateChatgptInput!){
        chatGptRequest( createChatgptInput : $createChatgptInput) {
          _id
          messages{ai, text}
    			type
        }
      }
    `;
const AI_RESUME_FEEDBACK = gql`
      mutation Airesumefeedback($createChatgptInput: CreateChatgptInput!){
        airesumefeedback( createChatgptInput : $createChatgptInput) {
          response
        }
      }
    `;
const CREATE_CREDIT = gql`
      mutation CreateCredit($createCreditInput: CreateCreditInput!){
        createCredit( createCreditInput : $createCreditInput) {
          _id
          amount
        }
      }
    `;
const CREATE_REFERRAL = gql`
      mutation CreateReferral($createReferralInput: CreateReferralInput!){
        createReferral( createReferralInput : $createReferralInput) {
          _id, createdAt, scout{_id name image},scouter{_id name image}, status, active, joblink, company{_id, name}
        }
      }
    `;
const CREATE_PROFILE = gql`
      mutation CreateProfile($createProfileInput: CreateProfileInput!){
        createProfile( createProfileInput : $createProfileInput) {
          _id
          location
          bio
          yearsOfExperience
          professionalTitle
          user{
            _id
            name

          }
        }
      }
    `;
const CONFIRM_CODE = gql`
      mutation ConfirmCode($updateUserInput: UpdateUserInput!){
        confirmCode( updateUserInput : $updateUserInput) {
          _id 
          name
          image 
          phone 
          role
          email
          confirmed
          employed
          company{
            _id
            name
          }
          category{
            _id
            name
          }
        }
      }
    `;
    const UPDATE_PROFILE = gql`
      mutation UpdateProfile($updateProfileInput: UpdateProfileInput!) {
        updateProfile( updateProfileInput :  $updateProfileInput) {
          _id
          location
          bio
          yearsOfExperience
          professionalTitle
          user{
            _id
            name

          }
          
        }
      }
    `;
const CREATE_RECOMMENDATION = gql`
      mutation CreateRecommendation($createRecommendationInput: CreateRecommendationInput!){
        createRecommendation( createRecommendationInput : $createRecommendationInput) {
          _id
          scout{
            _id
            name
          }
          status
          active
          company{
            _id
            name
          }
        }
      }
    `;
    const UPDATE_RECOMMENDATION = gql`
    mutation UpdateRecommendation($updateRecommendationInput: UpdateRecommendationInput!) {
      updateRecommendation( updateRecommendationInput :  $updateRecommendationInput) {
        _id
          scout{
            _id
            name
          }
          scouter{
            _id
            name
          }
          status
          active
          company{
            _id
            name
          }
        
      }
    }
  `;
const CREATE_COMPANY = gql`
      mutation CreateCompany($createCompanyInput: CreateCompanyInput!){
        createCompany( createCompanyInput : $createCompanyInput) {
          _id
          name
          description
          createdBy{_id, name, role}

        }
      }
    `;
const CREATE_CATEGORY = gql`
      mutation CreateCategory($createCategoryInput: CreateCategoryInput!){
        createCategory( createCategoryInput : $createCategoryInput) {
          _id
          name
          
        }
      }
    `;
const CREATE_CARRERGUIDE = gql`
      mutation CreateCarrerguide($createCarrerguideInput: CreateCarrerguideInput!){
        createCarrerguide( createCarrerguideInput : $createCarrerguideInput) {
          _id
          name
          description
        }
      }
    `;
const REMOVE_USER = gql`
      mutation RemoveUser($id: String!){
        removeUser(id : $id) {
          acknowledged
        }
      }
    `;
const REMOVE_COMPANY = gql`
      mutation RemoveCompany($id: String!){
        removeCompany(id : $id) {
          acknowledged
        }
      }
    `;
const REMOVE_CATEGORY = gql`
      mutation RemoveCategory($id: String!){
        removeCategory(id : $id) {
          acknowledged
        }
      }
    `;
const REMOVE_CARRERGUIDE = gql`
      mutation RemoveCarrerguide($id: String!){
        removeCarrerguide(id : $id) {
          acknowledged
        }
      }
    `;


const getCredits: any = async () => {
  try {
    const result = await client
      .query({
        query: gql`
          query getCredits{credits{_id, amount, recommendations[{_id, status, link, active, company }]}}
          `,
      })
      .then((result) => result);
    return result.data.credits;
  }
  catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error get credits", error.message)
  }
}
const getCategories: any = async () => {
  try {
    const result = await client
      .query({
        query: gql`
          query getCategories{categories{_id,  name, createdBy{_id, name, role }}}
          `,
      })
      .then((result) => result);
    return result.data.categories;
  }
  catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error get categories", error.message)
  }
}
const getNewsletter: any = async () => {
  try {
    const result = await client
      .query({
        query: gql`
          query getNewsletter{getNewsletter{_id, createdAt,  email,}}
          `,
      })
      .then((result) => result);
    return result.data.getNewsletter;
  }
  catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error get categories", error.message)
  }
}
const getCarrerguide: any = async () => {
  try {
    const result = await client
      .query({
        query: gql`
          query getCarrerguide{getcarrerguide{_id, createdAt,  name, description, createdBy{_id, name, role }}}
          `,
      })
      .then((result) => result);
    return result.data.getcarrerguide;
  }
  catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error get categories", error.message)
  }
}

const getCompanies: any = async () => {
  try {
    const result = await client
      .query({
        query: gql`
      query getCompanies{companies{_id, createdAt, name,description,category{_id, name} ,image, createdBy{_id, name, role }}}
      `,
      })
      .then((result) => result);
    return result.data.companies;
  }
  catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error al obtener el getCompanies", error.message)
  }
}
const getReferrals: any = async () => {
  try {
    const result = await client
      .query({
        query: gql`
      query geReferrals{referrals{_id, createdAt, name,description,category{_id, name} ,image, createdBy{_id, name, role }}}
      `,
      })
      .then((result) => result);
    return result.data.referrals;
  }
  catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error al obtener el getCompanies", error.message)
  }
}
const getUsers: any = async () => {
  try {
    const result = await client
      .query({
        query: gql`
      query getUsers{users{name,category{_id, name},company{_id, name}, email, _id,confirmed,employed, image, phone, role, createdAt}}
    `,
      })
      .then((result) => result);
    return result.data.users;
  }
  catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error al obtener el usuario", error.message)
  }
}
const getUser: any = () => {
  try {
    client
      .query({
        query: gql`
        query getUser{user{name, email,confirmed,employed, _id, image, phone, role,category{_id, name},company{_id, name}}}
      `,
      })
      .then((result) => console.log(result));
  } catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error al obtener el usuario", error.message)
  }

}
const filterCredit: any = async (data: any) => {
  try {
    const query = gql`
    query findCreditByFilter($updateCreditInput: UpdateCreditInput!) {
      creditFilter(updateCreditInput: $updateCreditInput){
        _id, amount, user{_id}, recommendations{_id, status, active, link}
      }
    }
`;
    const result: any = await client.query({
      query: query,
      variables: {
        updateCreditInput: data
      }
    })
    console.log(result);
    return result.data.creditFilter
  } catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error al obtener el usuario", error.message)
    return error.message

  }

}
const filterProfile: any = async (data: any) => {
  try {
    const query = gql`
    query findProfileByFilter($updateProfileInput: UpdateProfileInput!) {
      profileFilter(updateProfileInput: $updateProfileInput){
        _id user{_id name} bio createdAt location professionalTitle yearsOfExperience
      }
    }
`;
    const result: any = await client.query({
      query: query,
      variables: {
        updateProfileInput: data
      }
    })
    console.log(result);
    return result.data.profileFilter
  } catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error al obtener el profile", error.message)
    return error.message

  }

}
const filterChatgpt: any = async (data: any) => {
  try {
    const query = gql`
    query findChatgptByFilter($updateChatgptInput: UpdateChatgptInput!) {
      chatgptFilter(updateChatgptInput: $updateChatgptInput){
        _id user{_id name} createdAt response type job messages{ai,text} message pdf
      }
    }
`;
    const result: any = await client.query({
      query: query,
      variables: {
        updateChatgptInput: data
      }
    })
    console.log('result chatgpt filter',result);
    return result.data.chatgptFilter
  } catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error el chatgpt filter", error.message)
    return error.message

  }

}
const filterUser: any = async (data: any) => {
  try {
    const query = gql`
    query findUserByFilter($updateUserInput: UpdateUserInput!) {
      userFilter(updateUserInput: $updateUserInput){
        _id, name, email, confirmed,employed, image, phone, role,category{_id, name},company{_id, name}
      }
    }
`;
    const result: any = await client.query({
      query: query,
      variables: {
        updateUserInput: data
      }
    })
    console.log(result);
    return result.data.userFilter
  } catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error al obtener el user", error.message)
    return error.message

  }

}
const findProfileByUser: any = async (data: any) => {
  try {
    const query = gql`
    query findProfileByUser($user: String!) {
      findByUser(user: $user){
        _id user{_id name} bio createdAt location professionalTitle yearsOfExperience pdf
      }
    }
`;
    const result: any = await client.query({
      query: query,
      variables: {
        user: data
      }
    })
    console.log(result);
    return result.data.findByUser
  } catch (error: any) {
    /* const notData = {
      type: 'error',
      message: 'No user information',
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error al obtener el profile by user", error.message) */
    return false
  }
}
const filterRecommendation: any = async (data: any) => {
  try {
    const query = gql`
    query findRecommendationByFilter($updateRecommendationInput: UpdateRecommendationInput!) {
      recommendationFilter(updateRecommendationInput: $updateRecommendationInput){
        _id, createdAt, scout{_id name image}, scouter{_id name image}, status, active, link, company{_id, name}
      }
    }
`;
    const result: any = await client.query({
      query: query,
      variables: {
        updateRecommendationInput: data
      }
    })
    console.log(result);
    return result.data.recommendationFilter
  } catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error al obtener recommendation", error.message)
    return error.message

  }

}
const filterReferral: any = async (data: any) => {
  try {
    const query = gql`
    query findReferralByFilter($updateReferralInput: UpdateReferralInput!) {
      referralFilter(updateReferralInput: $updateReferralInput){
        _id, createdAt, message, email, scouter{_id email name image phone}, scout{_id email name image employed phone}, company{_id, name}, recommendation{_id createdAt link status active company{_id, name} }, status, active, joblink, company{_id, name}
      }
    }
`;
    const result: any = await client.query({
      query: query,
      variables: {
        updateReferralInput: data
      }
    })
    console.log(result);
    return result.data.referralFilter
  } catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error al obtener referral", error)
    return error.message

  }

}


const scrapLink: any = async (data: any) => {
  try {
    const query = gql`
    query scrapLink($createScrapInput: CreateScrapInput!) {
      scrapLink(createScrapInput: $createScrapInput){
        _id, result {
          title
          company
          location
          lastUpdate
          link
        }
      }
    }
`;
    const result: any = await client.query({
      query: query,
      variables: {
        createScrapInput: data
      }
    })
    console.log(result);
    return result.data.scrapLink
  } catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error al obtener scrap", error.message)
    return error.message

  }

}
const scrapFilter: any = async (data: any) => {
  try {
    const query = gql`
    query scrapFilter($updateScrapInput: UpdateScrapInput!) {
      scrapFilter(updateScrapInput: $updateScrapInput){
        _id, result {
          title
          company
          location
          lastUpdate
          link
        }
      }
    }
`;
    const result: any = await client.query({
      query: query,
      variables: {
        updateScrapInput: data
      }
    })
    console.log('result scrapfilter',result);
    return result.data.scrapFilter
  } catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error al obtener scrapFilter", error.message)
    return error.message

  }

}

const filterCompany: any = async (data: any) => {
  try {
    const query = gql`
  query findByFilter($updateCompanyInput: UpdateCompanyInput!) {
    companyFilter(updateCompanyInput: $updateCompanyInput){
      createdAt, name, _id, image, description, createdBy{_id, name}, category{_id, name}
    }
  }
`;

    const result: any = await client.query({
      query: query,
      variables: {
        updateCompanyInput: data
      }
    })
    console.log(result);
    return result.data.companyFilter
  } catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error al obtener el usuario", error.message)
    return error.message

  }

}

const filterCategory: any = async (data: any) => {
  try {


    const query = gql`
    query findByFilter($updateCategoryInput: UpdateCategoryInput!) {
      companyFilter(updateCategoryInput: $updateCategoryInput){
        createdAt, name, _id, createdBy{_id, name}
      }
    }
  `;

    const result: any = await client.query({
      query: query,
      variables: {
        updateCategoryInput: data
      }
    })
    console.log(result);
    return result.data.categoryFilter
  } catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error al obtener el usuario", error.message)
    return error.message

  }

}
const whoami: any = async () => {
  try {
    const result = await client
      .query({
        query: gql`
            query whoami{whoami{name,category{_id, name},company{_id, name},confirmed,employed, email, _id, image, phone, role}}
            `,
      })
    return result.data.whoami

  } catch (error: any) {
    const notData = {
      type: 'error',
      message: error.message,
      title: 'wescout',
      timeOut: 2500,
      callback: () => { },
      priority: true,
    }
    Notifications(notData)
    console.log("Error al obtener el usuario", error.message)
  }

}

export { client, CREATE_CREDIT, CREATE_REFERRAL, CREATE_PROFILE, CREATE_RECOMMENDATION, UPDATE_RECOMMENDATION, UPDATE_USER,UPDATE_CARRERGUIDE, UPDATE_CATEGORY,CONFIRM_CODE, UPDATE_PROFILE, UPDATE_COMPANY, REMOVE_COMPANY, REMOVE_CARRERGUIDE, REMOVE_CATEGORY, CREATE_USER, CHAT_GPT_RESPONSE, AI_RESUME_FEEDBACK, CREATE_CATEGORY, CREATE_CARRERGUIDE, CREATE_COMPANY, getUsers, getUser, AUTH_USER, whoami, getCredits, getNewsletter, getCarrerguide, getCategories, getCompanies, getReferrals, scrapFilter, filterCredit, filterCompany,scrapLink,findProfileByUser, filterChatgpt, filterUser, filterProfile,filterReferral, filterRecommendation, filterCategory }; // Exporte os módulos que você deseja usar em outras partes do seu aplicativo
